<template>
  <sign-page
    title-text="公文处理"
    :request="request"
    table-size="large"
    :title-menus="[]"
    :tableActions="tableActions"
    @tableAction="tableAction"
    :column-list="columnList"
    ref="docHandlePage"
    :table-actions-fixed="true"
    :tableActionsWidth="100">
  </sign-page>
</template>

<script>
import DocTitle from './cmp/docTitle'

import {
  officialDocumentHandleRequest as request
} from '../../api'

export default {
  created () {
    this.$store.dispatch('loadWorkerUserList')
  },
  activated () {
    this.$refs.docHandlePage.loadData()
  },
  methods: {
    async loadData () {
      let datas = await request.getMy()
      let dataMap = {}
      datas.forEach(v => {
        let key = v.officialDocumentId + '_' + v.workerId
        if (!dataMap[key]) {
          dataMap[key] = v
        } else {
          dataMap[key].status = v.status === 'over' ? 'over' : 'wait'
        }
      })
      return Object.keys(dataMap).map(v => dataMap[v])
    },
    async tableAction (parm) {
      this.chooseData = parm.data
      let action = parm.action
      if (action === 'detail') {
        this.$router.push({
          name: 'hrms.doc.detail',
          query: {
            id: this.chooseData.officialDocumentId,
            handle: 1
          }
        })
      }
    }
  },
  computed: {
    tableActions () {
      return [{
        key: 'detail',
        label: '查看'
      }]
    },
    columnList: {
      get () {
        let data = [{
          title: '',
          search: false,
          field: 'status',
          render: (h, rowData) => {
            return h('div', {
              domProps: {
                innerHTML: rowData && rowData.status === 'wait' ? '待办' : '已办'
              },
              style: {
                color: rowData.status === 'wait' ? '#F4628F' : null,
                'min-width': '40px'
              }
            })
          }
        },
        {
          title: '编码',
          sort: true,
          field: 'officialDocumentId'
        },
        {
          title: '文件名称',
          sort: true,
          field: 'officialDocumentTitle',
          render: (h, row) => {
            return h(DocTitle, {
              props: {
                title: row.officialDocumentTitle
              }
            })
          }
        },
        {
          title: '来文机关',
          sort: true,
          field: 'sendOrgName'
        },
        {
          title: '来文份数',
          sort: true,
          field: 'officialDocumentNum',
          dataType: Number
        },
        {
          title: '来文日期',
          sort: true,
          field: 'officialDocumentSendTime',
          dataType: Date,
          render: (h, rowData) => {
            return h('div', rowData && rowData.officialDocumentSendTime ? rowData.officialDocumentSendTime.slice(0, 10) : '-')
          }
        },
        {
          title: '来文号',
          sort: true,
          field: 'officialDocumentSendCode'
        },
        {
          title: '状态',
          sort: true,
          field: 'officialDocumentStatus',
          render: (h, rowData) => {
            return h('div', rowData && rowData.officialDocumentStatus === 'doing' ? '未结束' : '已结束')
          }
        }]
        return data
      }
    }
  },
  data () {
    return {
      request: {
        get: this.loadData
      },
      chooseData: null,
      modal: false
    }
  }
}
</script>
